<template>
    <label class="the-input gradient-template-gold-nav-bar animate__animated"
           :class="{'fill' : !!value,'animate__headShake' : !!invalidText}"
           tabindex="-1"
           :align="align || 'left'"
           :invalid="invalid || invalidText"
           :readonly="readonly">
        <input :type="show ? 'text' : type"
               :name="name"
               :placeholder="placeholder || ' '"
               :readonly="readonly || (options && options.length)"
               ref="input"
               v-model="data"
        >
        <transition name="fade">
            <span v-if="invalidText" dir="rtl" class="validation-error selected">{{invalidText}}</span>
        </transition>
        <slot name="info">
            <span class="info">
                <slot name="info-value">{{info}}</slot>
            </span>
        </slot>
        <slot name="dropdown">
            <div v-if="options && options.length" class="dropdown d-flex-c-ast-jfs-gap5">
                <div v-for="(option,index) in options" :key="index" class="dropdown-item"
                     @click.prevent="select(option)">
                    <slot name="option" :option="option">
                        {{option}}
                    </slot>
                </div>
            </div>
        </slot>
    </label>
</template>

<script>
    export default {
        name: "TheInput",
        props: ['value', 'type', 'placeholder', 'invalid', 'align', 'invalidText', 'readonly', 'name', 'options', 'reduce','info'],
        data() {
            return {
                show: false
            }
        },
        computed: {
            data: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                    this.$forceUpdate()
                }
            }
        },
        mounted() {
            if (this.options && this.options.length) {
                this.createInput()
            }
        },
        methods: {
            select(e) {
                this.$emit('input', e)
                this.blur()
            },
            blur() {
                document.getElementById("salehi_the_input_14258").focus()
            },
            createInput() {
                if (!document.getElementById("salehi_the_input_14258")) {
                    let el = document.createElement('input');
                    el.style = "position:absolute;height:0; width:0;left:0;top:0"
                    el.id = "salehi_the_input_14258"
                    document.body.appendChild(el);
                }
            }
        },
        beforeDestroy() {
            let el = document.getElementById("salehi_the_input_14258")
            if (el) {
                el.remove()
            }
        }
    }
</script>

<style lang="scss" scoped>

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        /*-webkit-text-fill-color: var(--fontcolor);*/
        -webkit-box-shadow:0 0 0 50px var(--input-back) inset;
        /*-webkit-background-clip: text;*/
        transition: color 9999s ease-out, background-color 9999s ease-out;
    }

    .the-input {
        height: 45px;
        width: 100%;
        /*border: 1px solid var(--yellow1);*/
        position: relative;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap: 10px;
        z-index: 5;

        input {
            flex-grow: 1;
            caret-color: var(--yellow1);
            color: var(--fontcolor);
            font-size: 16px;
            z-index: 1;
            min-width: 0;
            background-color: var(--input-back);
            border-radius: var(--xs-radius);
            padding: 0 10px;
        }

        svg {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 24px;
            cursor: pointer;
            z-index: 2;
            height: 100%;
            /*width: 30px;*/
        }

        .validation-error {
            font-size: 10px;
            position: absolute;
            top: calc(100% + 2px);
            left: 0;
            color: var(--red);
        }

        .info {
            position: absolute;
            left: 10px;
            right: auto;
            top: 0;
            bottom: 0;
            color: #f9f9f9A8;
            z-index: 1;
            display: flex;
            align-items: center;
            background-color: var(--input-back);
            /*padding-right: 10px;*/
        }

        .dropdown {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(100% + 5px);
            background-color: var(--input-back);
            border: 1px solid var(--yellow1);
            border-radius: var(--xs-radius);
            z-index: 2;
            padding: 5px;
            display: none;

            .dropdown-item {
                padding: 0 10px;
                border-radius: inherit;
                cursor: pointer;

                &:hover {
                    background-color: var(--dark);
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            inset: -1px;
            padding: 1px;
            background-color: var(--border);
            background-image: var(--linear-template);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 0;
            transition: background-size 0.3s, background-color 0.3s;
            z-index: 0;
            border-radius: var(--xs-radius);
        }

        &[align |="left"] {
            direction: ltr;
            text-align: left;

            .info {
                left: auto;
                right: 10px;
            }
        }

        &[align |="right"] {
            direction: rtl;
            text-align: right;
        }

        &[align *="center"] {
            text-align: center;
        }

        &.fill {
            &::before {
                background-color: var(--yellow1);
            }
        }

        &[invalid] {
            &::before {
                background-color: var(--red);
            }
        }

        &[readonly] {
            opacity: 0.7;

            &::before {
                background-size: 100%;
            }
        }

        &:focus-within {
            &::before {
                background-size: 100%;
            }

            .dropdown {
                display: flex;
            }
        }

    }
</style>