export function verify() {
    document.body.lastChild.style.left = document.body.lastChild.style.left === '0px' ? 'auto' : document.body.lastChild.style.left
    this.recaptcha = true
}

export let modal = {
    get() {
        return this.show
    },
    set() {
        this.$emit('close', false)
    }
}

export function validate(key) {
    let data = {}
    data[key] = this.data[key]
    // eslint-disable-next-line no-undef
    this.$validate(data, errorTemplate)
}

export function tradeTo(){
    return this.$route.params.tradeTo
}
export function tradeFrom(){
    return this.$route.params.tradeFrom
}