<template>
    <button class="btn" @click.prevent="$emit('click')" :type="type || 'button'">
        <!--<span class="back"/>-->
        <slot name="content">
            <span class="content d-flex-ac-jc-gap15">
                <slot></slot>
            </span>
        </slot>
    </button>
</template>

<script>
    export default {
        name: "TheButton",
        props:['type']
    }
</script>

<style lang="scss" scoped>
    .btn {
        $border : 1px;
        --radius: var(--xs-radius);
        --border-w: -$border;
        display: flex;
        flex-direction: row;
        //width: 100%;
        justify-content: stretch;
        align-items: stretch;
        font-size: 16px;
        font-weight: 600 !important;
        border-radius: var(--radius);
        height: 40px !important;
        cursor: pointer;
        outline: none;
        /*flex-grow: 1;*/
        background-color: transparent;
        padding: 0;
        color: var(--fontcolor);

        .content{
            width: 100%;
            height: 100%;
            font-weight: inherit;
            font-size: 16px;
            border-radius: inherit;
            z-index: 1;
            padding: 0 20px;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            a{
                cursor: not-allowed;
            }
        }

        &[flat],&[border],&[flat-light]{
            position: relative;
            z-index: 1;
            &:before{
                content: '';
                position: absolute;
                inset: 0;
                background: var(--linear-template);
                z-index: 0;
                border-radius: inherit;
                transition: filter 0.3s;
            }
            &:hover{
                &:before{
                    filter: blur(5px);
                }
            }
        }

        &[flat] {
            .content{
                background: var(--linear-template);
            }
        }

        &[flat-light]{
            .content{
                background: #D1B476;
                color: #3D3A3A;
            }
        }

        &[border] {
            .content{
                background: var(--semiDark);
            }

            &::before {
                inset: -$border;
            }

            &:after{
                content: '';
                position: absolute;
                inset: -$border;
                background: var(--linear-template);
                z-index: 0;
                border-radius: inherit;
            }
        }

        &[mini] {
            max-width: 170px;
        }
    }
</style>