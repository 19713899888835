<template>
    <form @submit.prevent="submit" class="login" @change="validate($event.target.name)">
        <div class="d-flex-r-jsb-ac">
            <a class="selected">ثبت نام</a>
            <a :href="$href({name : 'Login'})" @click.prevent="$push({name : 'Login'})">ورود</a>
        </div>

        <the-input v-model="data.email" type="text" :align="data.email? 'left' : 'right'"
                   :invalid-text="state.error.email" name="email" placeholder="ایمیل"/>
        <the-input v-model="data.password" type="password" :align="data.password? 'left' : 'right'"
                   :invalid-text="state.error.password" name="password" placeholder="گذرواژه"/>
        <the-input v-model="data.confirmationPass" type="password" :align="data.confirmationPass? 'left' : 'right'"
                   :invalid-text="state.error.confirmationPass" name="confirmationPass" placeholder="تکرار گذرواژه"/>
        <the-input v-model="data.referralCode" :align="data.referralCode? 'left' : 'right'"
                   :invalid-text="state.error.referralCode" placeholder="کد دعوت (اختیاری)"/>

        <vue-recaptcha class="d-flex-r-ac-g1-s1" sitekey="6LezA0gcAAAAAGAcICq898569tI4DMHOfiYos_oo"
                       :key="theme" :size="theme"
                       theme="light" language="fa"
                       :loadRecaptchaScript="true"
                       @verify="recaptcha=true" @expired="recaptcha=false" @error="recaptcha=false"/>

        <p class="align-start m-font policy">
            <a class="mini-link text-gradient-hover" :href="$href({name : 'Register'})"
               @click.prevent="$push({name : 'Policy'})">قوانین و مقررات</a>
            را مطالعه نموده و میپذیرم.
        </p>
        <the-button flat class="gradient-template-linear-pink" type="submit" :title="Object.values(state.error)">
            ورود
        </the-button>
        <the-button border class="gradient-template-google" type="button">
            ورود / ثبت نام با گوگل
            <inline-svg :src="require('@/assets/Icons/Google-Logo.svg')" style="margin-right: 15px"/>
        </the-button>
    </form>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';
    import TheInput from "@/components/Tools/TheInput";
    import TheButton from "@/components/Tools/TheButton";
    import {validate} from "@/lib/reuseableFuncrtions";
    // import Small from "@/components/Tools/small";

    const errorTemplate = {
        email: {
            required: true,
            regexName: 'email'
        },
        password: {
            required: true,
            minLength: 8,
            regexName: 'password'
        },
        confirmationPass: {
            required: true,
        }
    }

    export default {
        name: "Register",
        components: {TheButton, TheInput, VueRecaptcha},
        data() {
            return {
                data: {
                    email: '',
                    password: '',
                    confirmationPass: '',
                    osType: '',
                    deviceType: '',
                    browser: '',
                    referralCode: '',
                },
                recaptcha: false,
                theme: 'normal',
            }
        },
        watch: {},
        methods: {
            async submit() {
                if (await this.$validate(this.data, errorTemplate)) {
                    if (this.data.confirmationPass === this.data.password) {
                        if (this.recaptcha) {
                            this.$cookies.set('username', this.data.email)
                            this.state.userInfo.email = this.data.email
                            this.$push({name: 'OTP', params: {pathMatch: 'EMAIL'}})
                        }
                    } else {
                        this.state.error = {confirmationPass : 'تکرار گذرواژه اشتباه است'}
                    }
                }
            },
            getData() {
                this.data.osType = this.$detectOs()
                this.data.browser = this.$detectBrowser() || 'CHROME'
                this.data.deviceType = this.$detectDevice()
            },
            validate,
        },
        mounted() {
            this.theme = window.screen.width < 340 ? 'compact' : 'normal'
            this.getData()
            if (this.$route.query.ref || localStorage.referralCode) {
                localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
                this.data.referralCode = this.$route.query.ref || localStorage.referralCode
            }
        },
        beforeDestroy() {
            console.log(document.body.lastChild.remove())
        }
    }
</script>

<style lang="scss" scoped>
    .mini-link {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        /*text-decoration-color: var(--blue1);*/
        text-underline-offset: 5px;
    }

    .policy {
        margin: -10px 0;
        font-size: 12px;
    }
</style>